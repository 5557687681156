@use 'breakpoints';

.page__inner {
  margin-top: 16px;
}

.secure-site__nav-container--custom-fp {
  display: grid;
  grid-template-columns: 56px 100%; // 56px for fp-cta (32px icon button + right spacing of 24px)
  align-items: center;

  @media screen and (min-width: breakpoints.$max-small-viewport) {
    align-items: baseline;
  }

  .secondary-nav {
    padding-right: 56px;
    margin-bottom: 24px;

  }
}

:root {
  --fp-drawer-width: #{rem(400)};
  --fp-drawer-push-secondary-nav: 0;
}

.fp-drawer--push-right--secondary-nav {
  --fp-drawer-push-secondary-nav: calc(var(--fp-drawer-width) / 2);

  .page__inner {
    margin-left: 192px;
    margin-right: 200px;
    max-width: none;
    padding: 0px;
    width: initial;
    transition-duration: $animation-timing-expand-large;
    transition-timing-function: $animation-easing-accelerate-web;
  }

  ss-lib-secondary-nav {
    display: block;
    max-width: 1300px;
    margin: auto;
    padding-inline: 56px;
  }
}

:host ::ng-deep {

  @media screen and (max-width: 600px) {
    ss-lib-secondary-nav {
      width: 95vw !important;
    }
  }
}

.page__inner {
  transition-duration: $animation-timing-expand-large;
  transition-timing-function: $animation-easing-accelerate-web;
}

.page__inner {
  transform: translateX(var(--fp-drawer-push-secondary-nav));
  transition-property: transform;
  transition-timing-function: $animation-easing-decelerate-web;
  transition-duration: $animation-timing-collapse-large;
  transform-style: flat;
  backface-visibility: hidden;
}