@import '_vg-colors.scss';
@import 'icons.scss';
@import 'breakpoints.scss';
@import 'typography.scss';
@import 'materialCustomization.scss';
@import 'utility.scss';
@import '@vg-constellation/angular-13/styles/c11n';
@import '@vg-constellation/angular-18/styles/c11n';
@import '@vg-constellation/styles/c11n';
@import '@vg-constellation/styles/icons';
@import '@vg-constellation/styles/styles.css';
@import '@vg-constellation/styles/typography';
@import '@vg-constellation/angular-13/styles/variables/tokens';
@import '@vg-constellation/angular-18/styles/variables/tokens';
@import 'secureSiteNavCustomization.scss';

// default styles
body {
  background-color: $vg-white;
  margin: 0;
  overflow-x: scroll;
}

.global-footer {
  margin-top: 80px !important;
}

blockquote,
button,
dd,
div,
dl,
figcaption,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
p,
section {
  margin: revert;
  padding: revert;
  font-weight: revert;
}

h4 {
  margin: 0;
  padding: 0;
  line-height: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: normal;
}

// footer styling
.vgn-socialFooter,
.vgn-superFooter,
.vgn-legalFooterM {
  background-color: $background-gray;
}

.vgn-superFooter .vgn-container {
  background-color: $background-gray;
}

//icon text styling
.center-vertically {
  vertical-align: middle;
}

//tester tool styles

PW1-tester-tool {
  display: flex;
  justify-content: center;
  position: relative;
}

.wait * {
  cursor: wait !important;
  pointer-events: none;
}

.faq_table {
  .header {
    padding: 12px !important;
    text-align: left !important;
    width: 50%;
    background-color: $background-gray !important;
  }
  .data {
    padding: 12px !important;
    font-size: 18px;
  }
}

@media print {
  #vgn-globalFooter {
    display: none !important;
  }

  .print-disclaimer__body b {
    font-weight: normal;
  }

  c11n-banner b {
    font-weight: normal;
  }

  c11n-banner a.c11n-link {
    font-weight: normal;
    color: inherit;
    text-decoration: none;
  }
}

.totalRow {
  font-family: MarkPro-Narrow, Arial, sans-serif;
  font-weight: bold;
  border-top: 1px solid $modern-header-gray;
  border-bottom: none;
}

.c11n-remove-small {
  width: 14px;
  height: 14px;
}

@media screen and (max-width: 960px) {
  pw1-tester-tool{
    width: 94vw;    
  }
  body {
    overflow-x: hidden;
  }
}

.flex-row, .flex-column {
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;      
}

.flex-column {
  flex-direction: column;
}

